import React, { ReactElement, useEffect } from 'react'
import labelConfig from '../components/labels/config'
import EditorScreen from '../components/screens/editor-screen'
import GoogleAnalytics from '../lib/google-analytics'
import { useAppState } from '../state/app-context'
import { EditorProvider } from '../state/editor-context'
import { Page } from '../types/page'
import { EditorState } from '../types/state'

export default function EditLabelTemplate({ pageContext }: Page.TemplateEditLabelProperties): ReactElement {
  const { language, title, label, id, media } = pageContext
  const { store } = useAppState()

  useEffect(() => {
    GoogleAnalytics.sendLabelCreationStart(store)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultState = Object.keys(label).reduce(
    (state, current) => {
      return !label[current] ? state : { ...state, [current]: '' }
    },
    { currentStep: 0, id } as EditorState.State,
  )

  return (
    <EditorProvider defaultState={defaultState}>
      <EditorScreen id={id} language={language} media={media} title={title} configuration={labelConfig[id]} />
    </EditorProvider>
  )
}
