import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react'
import useCensoredWords from '../../../hooks/use-censored-words'
import useFocused from '../../../hooks/use-focused'
import useRequiredBecameEmpty from '../../../hooks/use-required-became-empty'
import { useAppState } from '../../../state/app-context'
import { useEditorDispatch, useEditorState } from '../../../state/editor-context'
import { Component } from '../../../types/components'
import { EditorDispatchType } from '../../../types/enums'
import { Control, InputField, MaxLengthIndicator } from '../../elements'
import InputErrorMessage from '../../elements/input-error-message'
import Tooltip from '../../grouping/tooltip'
import labelConfig from '../../labels/config'

export default function MainField({ reportError }: Component.LabelFormField) {
  const { mainField, id, isCensoredWordsAllowed } = useEditorState()
  const { appLanguage } = useAppState()
  const dispatch = useEditorDispatch()
  const mainFieldMax = labelConfig[id]?.mainField?.max || 20
  const becameEmpty = useRequiredBecameEmpty(mainField || '')
  const { cleanedWord, containsBadWord } = useCensoredWords(mainField, appLanguage, isCensoredWordsAllowed)
  const [focused, onFocus, onBlur] = useFocused()
  const inputElement = useRef<HTMLDivElement>()

  const fieldError = useMemo(() => becameEmpty || containsBadWord, [becameEmpty, containsBadWord])

  useEffect(() => {
    reportError(fieldError)
  }, [fieldError, reportError])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: EditorDispatchType.updateField,
      payload: {
        key: 'mainField',
        value: event.target.value,
      },
    })
  }

  const onInputFocus = () => {
    onFocus()
    dispatch({
      type: EditorDispatchType.updateFormFocus,
      payload: {
        focused: true,
        focusedElement: inputElement,
      },
    })
  }

  const onInputBlur = () => {
    onBlur()
    dispatch({
      type: EditorDispatchType.updateFormFocus,
      payload: {
        focused: false,
      },
    })
  }

  return (
    <InputField hasMaxLength hasTooltip error={becameEmpty || containsBadWord} ref={inputElement} id="mainField">
      <Control
        label={{
          htmlFor: 'mainField-field',
          className: 'flex align-center',
          children: (
            <>
              Heading <span className="required-star">*</span>
              <Tooltip>
                <p>
                  To change between the keyboards, tap and hold the Globe icon in the bottom-left corner of the screen
                  to change between the languages.
                </p>
                <p>To add languages to your device go here.</p>
              </Tooltip>
            </>
          ),
        }}
        input={{
          value: focused ? mainField : cleanedWord,
          onChange,
          name: 'mainField-field',
          id: 'mainField-field',
          autoComplete: 'off',
          maxLength: mainFieldMax,
          onFocus: onInputFocus,
          onBlur: onInputBlur,
        }}
      />
      <MaxLengthIndicator maxLength={mainFieldMax} currentValue={mainField} />
      {becameEmpty && (
        <InputErrorMessage>*This section is mandatory, please enter in the heading for the label</InputErrorMessage>
      )}
      {!becameEmpty && containsBadWord && (
        <InputErrorMessage>Inappropriate message choice. Please choose something else. </InputErrorMessage>
      )}
    </InputField>
  )
}
