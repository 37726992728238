import React, { MouseEvent } from 'react'
import { Grouping } from '../../types/grouping'
import { Button } from '../elements'

export default function Tooltip({ children, className = '' }: Grouping.Tooltip) {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const onClick = (event: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <div className={`c__tooltip ${className}`}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className="c__tooltip__trigger" onClick={onClick}>
        <Button variant="link" onClick={onClick}>
          <span className="sr-only">Open Tooltop</span>
        </Button>
      </div>
      <div className="c__tooltip__content u__mc--vertical">{children}</div>
    </div>
  )
}
