import { navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { ReactNode, useCallback, useMemo } from 'react'

import { useEditorDispatch, useEditorState } from '../../state/editor-context'
import { Component } from '../../types/components'
import { EditorDispatchType, EditScreens } from '../../types/enums'
import { Page } from '../../types/page'
import ConfirmLabelForm from '../components/confirm-label-form'
import CreateLabelForm from '../components/create-label-form'
import PageLayout from '../components/page-layout'
import { Section } from '../elements'
import { SingleLabelConfig } from '../labels/config'
import BaseLabel from '../labels/core/base-label'
import FinishHeader from './edit-label/finish-header'
import PrintingScreen from './edit-label/printing-screen'
import StepThreeHeader from './edit-label/step-three-header'
import StepTwoHeader from './edit-label/step-two-header'
import VideoScreen from './edit-label/video-screen'
import Icons from '../labels/svgs'

type EditorScreenProperties = Omit<
  Page.TemplateEditLabelPageContext,
  'flagshipAlternative' | 'isFlagship' | 'regions' | 'slug' | 'image' | 'generalInLanguage' | 'label' | 'comingSoon'
> & {
  configuration: SingleLabelConfig
}

const HeaderWrapper = ({ child }: { child: ReactNode }) => (
  <div className="row">
    <div className="col">{child}</div>
  </div>
)

export default function EditorScreen({ language, configuration, media, id }: EditorScreenProperties) {
  const { mainField, subField, batchField, currentStep } = useEditorState()
  const dispatch = useEditorDispatch()
  const largeImage = getImage(media.large)
  const LabelSVG = Icons[id]

  const labelConfiguration = useMemo(() => {
    const config: Component.BaseLabelFields = {
      mainField: { ...configuration.mainField, value: mainField, bottleID: id },
      subField: configuration.subField ? { ...configuration.subField, value: subField || '', bottleID: id } : undefined,
      batchField: configuration.batchField
        ? { ...configuration.batchField, value: batchField || '', bottleID: id }
        : undefined,
    }

    return config
  }, [batchField, configuration.batchField, configuration.mainField, configuration.subField, id, mainField, subField])

  const onBack = () => {
    if (currentStep === EditScreens.personalise) {
      navigate(`/${language}/bottle-selection/`)
    } else if (currentStep === EditScreens.finish) {
      dispatch({
        type: EditorDispatchType.changeStep,
        payload: EditScreens.printing,
      })
    } else {
      dispatch({
        type: EditorDispatchType.previousStep,
      })
    }
  }

  const renderHeader = useCallback(() => {
    const HeaderComponent = () => {
      switch (currentStep) {
        case EditScreens.personalise:
          return <HeaderWrapper child={<StepTwoHeader />} />
        case EditScreens.confirm:
          return <HeaderWrapper child={<StepThreeHeader />} />
        case EditScreens.finish:
          return <HeaderWrapper child={<FinishHeader />} />
        default:
          return <></>
      }
    }

    return <HeaderComponent />
  }, [currentStep])

  const renderForm = useMemo(() => {
    const FormComponent = () => {
      switch (currentStep) {
        case EditScreens.personalise:
          return <CreateLabelForm />
        case EditScreens.confirm:
          return <ConfirmLabelForm />
        default:
          return <></>
      }
    }

    return <FormComponent />
  }, [currentStep])

  const renderComponentBody = useMemo(() => {
    switch (currentStep) {
      case EditScreens.personalise:
      case EditScreens.confirm:
      case EditScreens.finish:
        return (
          <>
            {renderHeader()}
            <div className="row" style={{ position: 'relative' }} id="form-row-wrapper">
              <div className="c__label-form col-40">{renderForm}</div>
              <div className="c__label-img col-60">
                {currentStep !== EditScreens.finish && (
                  <BaseLabel
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...labelConfiguration}
                    className={`c__base-label--${id}`}
                  >
                    <LabelSVG className="animated animated--fade-in-up" />
                  </BaseLabel>
                )}
                <div className={`c__background-bottle ${id}`}>
                  {largeImage && <GatsbyImage image={largeImage} alt="TEST" />}
                </div>
              </div>
            </div>
          </>
        )
      case EditScreens.printing:
        return (
          <div className="row" style={{ marginTop: 0, marginBottom: 0 }}>
            <PrintingScreen />
          </div>
        )
      case EditScreens.videoTutorial:
        return <VideoScreen />
      default:
        return <></>
    }
  }, [currentStep, renderHeader, renderForm, labelConfiguration, id, LabelSVG, largeImage])

  return (
    <PageLayout lang={language} onBack={onBack}>
      <Section className="c__edit-label u__hide-on-print">
        <div className="container">
          <div className="row flex justify-center">
            <div className="col-90 c__edit-wrapper">{renderComponentBody}</div>
          </div>
        </div>
      </Section>
    </PageLayout>
  )
}
