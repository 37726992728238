import React, { ChangeEvent, useEffect, useRef } from 'react'
import useCensoredWords from '../../../hooks/use-censored-words'
import useFocused from '../../../hooks/use-focused'
import { useAppState } from '../../../state/app-context'
import { useEditorDispatch, useEditorState } from '../../../state/editor-context'
import { Component } from '../../../types/components'
import { EditorDispatchType } from '../../../types/enums'
import { Control, InputField, MaxLengthIndicator } from '../../elements'
import InputErrorMessage from '../../elements/input-error-message'
import labelConfig from '../../labels/config'

export default function SubField({ reportError }: Component.LabelFormField) {
  const { subField, id, isCensoredWordsAllowed } = useEditorState()
  const { appLanguage } = useAppState()
  const dispatch = useEditorDispatch()
  const subFieldMax = labelConfig[id]?.subField?.max || 20
  const { cleanedWord, containsBadWord } = useCensoredWords(subField, appLanguage, isCensoredWordsAllowed)
  const [focused, onFocus, onBlur] = useFocused()
  const inputElement = useRef<HTMLDivElement>()

  useEffect(() => {
    reportError(containsBadWord)
  }, [containsBadWord, reportError])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: EditorDispatchType.updateField,
      payload: {
        key: 'subField',
        value: event.target.value,
      },
    })
  }

  const onInputFocus = () => {
    onFocus()
    dispatch({
      type: EditorDispatchType.updateFormFocus,
      payload: {
        focused: true,
        focusedElement: inputElement,
      },
    })
  }

  const onInputBlur = () => {
    onBlur()
    dispatch({
      type: EditorDispatchType.updateFormFocus,
      payload: {
        focused: false,
      },
    })
  }

  return (
    <InputField hasMaxLength error={containsBadWord} ref={inputElement} id="subField">
      <Control
        label={{
          className: 'e__label--with-optional-label',
          htmlFor: 'subField-field',
          children: (
            <>
              Sub-Heading <span className="optional-label">(Optional)</span>
            </>
          ),
        }}
        input={{
          value: focused ? subField : cleanedWord,
          onChange,
          name: 'subField-field',
          id: 'subField-field',
          autoComplete: 'off',
          maxLength: subFieldMax,
          onFocus: onInputFocus,
          onBlur: onInputBlur,
        }}
      />
      <MaxLengthIndicator maxLength={subFieldMax} currentValue={subField} />
      {containsBadWord && (
        <InputErrorMessage>Inappropriate message choice. Please choose something else. </InputErrorMessage>
      )}
    </InputField>
  )
}
