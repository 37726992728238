// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import useIsExperimental from '../../../hooks/use-is-experimental'
import useIsFlagship from '../../../hooks/use-is-flagship'
import { useEditorState } from '../../../state/editor-context'
import { Component } from '../../../types/components'
import LabelField, { LABEL_FIELD_CLASSNAME } from './label-field'

const DEBUG_MODE = false

export default function BaseLabel({ mainField, subField, batchField, children, className = '' }: Component.BaseLabel) {
  const { formFocused, focusedElement } = useEditorState()
  const labelElement = useRef<HTMLDivElement>()
  const [mainFieldY, setMainFieldY] = useState(mainField.y)
  const isFlagship = useIsFlagship(mainField.bottleID)
  const isExperimental = useIsExperimental(mainField.bottleID)

  useEffect(() => {
    if (isExperimental) {
      setMainFieldY(mainField.y)
    } else {
      const offset = isFlagship ? 12 : 15
      setMainFieldY(!subField?.value ? mainField.y + offset : mainField.y)
    }
  }, [subField, mainField.y, isExperimental, isFlagship])

  const getInputConfig = useCallback(
    (id: string) => {
      switch (id) {
        case 'mainField':
          return mainField
        case 'subField':
          return subField
        case 'batchField':
          return batchField
        default:
          throw new Error('Unknown input field')
      }
    },
    [batchField, mainField, subField],
  )

  const labelPosition = useMemo(() => {
    if (focusedElement?.current && formFocused) {
      const { id } = focusedElement.current
      const config = getInputConfig(id)
      const formWrapper = document.querySelector('#form-row-wrapper')

      if (config && formWrapper) {
        const { labelPositionOnFocus } = config
        const parentRect = formWrapper.getBoundingClientRect()

        const relativeTop = focusedElement.current.getBoundingClientRect().top - parentRect.top

        switch (labelPositionOnFocus) {
          case 'top':
            return relativeTop
          case 'bottom':
            return `-40%`
          case 'middle':
          default:
            return `-25%`
        }
      }
    }

    return 0
  }, [focusedElement, getInputConfig, formFocused])

  return (
    <div
      id="c__base-label"
      className={`c__base-label ${className}`}
      ref={labelElement}
      style={{ top: formFocused ? labelPosition : 0, position: formFocused ? 'absolute' : 'relative' }}
    >
      <div className="c__base-label__fields">
        <LabelField
          className={`${LABEL_FIELD_CLASSNAME}--main-field`}
          {...mainField}
          y={mainFieldY}
          debugMode={DEBUG_MODE}
        />
        {subField && (
          <LabelField className={`${LABEL_FIELD_CLASSNAME}--sub-field`} {...subField} debugMode={DEBUG_MODE} />
        )}
        {batchField && (
          <LabelField className={`${LABEL_FIELD_CLASSNAME}--batch-field`} {...batchField} debugMode={DEBUG_MODE} />
        )}
      </div>
      {children}
    </div>
  )
}
