import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useCensoredWords from '../../../hooks/use-censored-words'
import { useEditorState } from '../../../state/editor-context'
import resizedFontSize from '../../../hooks/use-resized-font-size'
import { useAppState } from '../../../state/app-context'
import { Component } from '../../../types/components'
import IsBrowser from '../../../utils/is-browser'
import CurvePath, { getNudgeValue, getTextFillFontSize } from './curve-path'

export const LABEL_FIELD_CLASSNAME = 'c__label-field'
const LABEL_FIELD_DEBUG_CLASSNAME = `${LABEL_FIELD_CLASSNAME}--debug`
const MAX_LABEL_SIZE = 486

export default function LabelField({
  x,
  y,
  width,
  height,
  bottleID,
  size: fontSize,
  value = '',
  debugMode = false,
  alignment = 'center',
  className = '',
}: Component.LabelField) {
  const element = useRef<HTMLDivElement>()
  const { isCensoredWordsAllowed, formFocused } = useEditorState()
  const { appLanguage } = useAppState()
  const { wordForLabel } = useCensoredWords(value, appLanguage, isCensoredWordsAllowed)
  const [pathFontSize, setPathFontSize] = useState(fontSize)
  const [nudge, setNudge] = useState<Component.TNudge>('none')
  const [scalePercentage, setScalePercentage] = useState(1)

  const scaleLabelDisplay = useCallback(() => {
    setTimeout(() => {
      const label = document.querySelector('#c__base-label > svg')
      const maxLabelSize = (['vat01','vat02','vat03','vat04'].indexOf(bottleID) > -1) ? 400 : MAX_LABEL_SIZE
      const boxWidth = label?.getBoundingClientRect().width || maxLabelSize
      const percentageDiff = boxWidth / maxLabelSize
      setScalePercentage(percentageDiff)
    }, 0)
  }, [])

  useEffect(() => {
    scaleLabelDisplay()
  }, [formFocused, scaleLabelDisplay])

  useEffect(() => {
    const onResize = () => {
      scaleLabelDisplay()
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [scaleLabelDisplay])

  useEffect(() => {
    if (element.current && IsBrowser()) {
      resizedFontSize(element, fontSize, () => {
        const textfillElement: HTMLSpanElement = element.current.querySelector('span')

        if (textfillElement) {
          const size = getTextFillFontSize(textfillElement)
          setPathFontSize(size)
          setNudge(getNudgeValue(size))
        }
      })
    }
  }, [wordForLabel, fontSize, scalePercentage])

  const styles = useMemo(() => {
    return {
      left: `calc(${x}px * ${scalePercentage})`,
      top: `calc(${y}px * ${scalePercentage})`,
      width,
      height: `calc(${height}px * ${scalePercentage})`,
      lineHeight: `calc(${height}px * ${scalePercentage})`,
      textAlign: alignment,
    }
  }, [x, y, height, width, alignment, scalePercentage])

  const showCurvePath = useMemo(() => {
    return bottleID === 'aged-23-years' && className.includes('main-field')
  }, [bottleID, className])

  return (
    <>
      {showCurvePath && <CurvePath fontSize={pathFontSize} nudge={nudge} content={wordForLabel} />}
      <div
        ref={element}
        style={styles}
        className={`${LABEL_FIELD_CLASSNAME} ${debugMode ? LABEL_FIELD_DEBUG_CLASSNAME : ''} ${className}`}
      >
        <span>{wordForLabel}</span>
      </div>
    </>
  )
}
