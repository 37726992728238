import React, { MouseEvent } from 'react'
import GoogleAnalytics from '../../../lib/google-analytics'
import { useAppState } from '../../../state/app-context'
import { navigateToGetStarted } from '../../../utils/navigate'
import { AnchorButton } from '../../elements'
import { PageTitle } from '../../grouping'

export default function FinishHeader() {
  const { appLanguage, store } = useAppState()

  const onFinish = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    GoogleAnalytics.sendLabelCreationFinish(store)

    navigateToGetStarted(appLanguage)
  }

  return (
    <div className="c__finish-header">
      <PageTitle
        title={
          <>
            You are
            <br />
            all done!
          </>
        }
        subTitle="Finish"
        headingElement="h1"
      />
      <p className="c__label-check">
        Check label supply to make sure you have enough in stock and order more if needed.{' '}
      </p>
      <p className="c__label-btn">
        <AnchorButton href={`/${appLanguage}/`} onClick={onFinish}>
          Finish
        </AnchorButton>
      </p>
    </div>
  )
}
