import React from 'react'
import { VIDEO_PATH } from '../../types/constants'
import { Alert } from '../grouping'
import isOnline from '../../utils/get-online-status'

export default function HowToApplyVideo() {
  return (
    <div className="c__how-to-apply-video box">
      {!isOnline && (
        <Alert>
          <p>
            You are currently offline. If you downloaded the video during the onboarding, the video will be available in
            the <strong>Files</strong> app on your device. If not, please connect to a network to view the video.
          </p>
        </Alert>
      )}
      {isOnline && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video autoPlay controls crossOrigin="anonymous">
          <source src={VIDEO_PATH} type="video/mp4" />
        </video>
      )}
    </div>
  )
}
