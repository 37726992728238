import React, { MouseEvent } from 'react'
import { useEditorDispatch } from '../../../state/editor-context'
import { EditorDispatchType } from '../../../types/enums'
import HowToApplyVideo from '../../components/how-to-apply-video'
import { ArrowRightIcon, Button } from '../../elements'

export default function VideoScreen() {
  const dispatch = useEditorDispatch()

  const onNext = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    dispatch({
      type: EditorDispatchType.nextStep,
    })
  }

  return (
    <div className="c__video-screen">
      <HowToApplyVideo />
      <Button variant="link" iconDirection="right" onClick={onNext}>
        Next <ArrowRightIcon />
      </Button>
    </div>
  )
}
