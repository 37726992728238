import React, { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react'
import printLabel from '../../../lib/print-label'
import GoogleAnalytics from '../../../lib/google-analytics'
import { useEditorDispatch, useEditorState } from '../../../state/editor-context'
import { EditorDispatchType, EditScreens } from '../../../types/enums'
import { OnBoardingTemplate } from '../../../types/templates'
import IsBrowser from '../../../utils/is-browser'
import PrintableSpace from '../../components/printable-space'
import { ArrowRightIcon, Button, List, LoadingSVG, TestPrintIcon } from '../../elements'
import OnBoarding from '../../templates/onboarding'
import ShareIcon from '../../../images/icon-share.svg'

const pageTitle: OnBoardingTemplate['pageTitle'] = {
  subTitle: 'Printing',
  title: 'time to print',
}

const ACTION_CONTENT = 'Generate label'

export default function PrintingScreen(): ReactElement {
  const [printAttempted, setPrintAttempted] = useState(false)
  const [generating, setGenerating] = useState(true)
  const dispatch = useEditorDispatch()
  const { mainField, subField, batchField, id } = useEditorState()
  const printableElement = useRef<HTMLDivElement>()

  useEffect(() => {
    setTimeout(() => {
      setGenerating(false)
    }, 500)
  }, [])

  const onContinue = (event?: MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault()
    }

    GoogleAnalytics.sendLabelMessage({ mainField, subField, batchField })

    dispatch({
      type: EditorDispatchType.changeStep,
      payload: EditScreens.finish,
    })
  }

  const onPrint = async (event: MouseEvent<HTMLButtonElement>) => {
    setGenerating(true)
    event.preventDefault()

    if (IsBrowser() && printableElement.current) {
      setTimeout(async () => {
        await printLabel.execute(printableElement.current)
        setPrintAttempted(true)
        setGenerating(false)
        onContinue()
      }, 250)
    }
  }

  return (
    <>
      <PrintableSpace
        ref={printableElement}
        id={id}
        className={id}
        mainHeading={mainField}
        subHeading={subField}
        batchField={batchField}
      />
      <OnBoarding className="u__hide-on-print c__printing-screen" icon={<TestPrintIcon />} pageTitle={pageTitle}>
        <List className="u__mc--vertical" type="ol" fancy>
          <li>Make sure the printer is on and connected</li>
          <li>Put the label into the printer tray, with the label facing upwards.</li>
          <li>
            Click on the &quot;{ACTION_CONTENT}&quot; button below. Once the dialog appears, click on the{' '}
            <ShareIcon style={{ display: 'inline-block', width: 32, height: 32, verticalAlign: 'middle' }} /> share icon
            and &quot;Print&quot;.
          </li>
        </List>
        <p className="c__label-btn">
          <Button onClick={onPrint} className={generating ? 'loading' : ''}>
            {generating ? <LoadingSVG /> : ACTION_CONTENT}
          </Button>
          {printAttempted && (
            <Button variant="link" iconDirection="right" onClick={onContinue} className="continue-button">
              Next <ArrowRightIcon />
            </Button>
          )}
        </p>
      </OnBoarding>
    </>
  )
}
