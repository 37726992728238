import Aged12YearsFlagship from '../../../images/labels/aged-12-years-flagship.svg'
import Aged12Years from '../../../images/labels/aged-12-years.svg'
import Aged14YearsFlagship from '../../../images/labels/aged-14-years-flagship.svg'
import Aged15YearsFlagship from '../../../images/labels/aged-15-years-flagship.svg'
import Aged15Years from '../../../images/labels/aged-15-years.svg'
import Aged15YearsGTR from '../../../images/labels/aged-15-years-gtr.svg'
import Aged18Years from '../../../images/labels/aged-18-years.svg'
import Aged21YearsSVG from '../../../images/labels/aged-21-years.svg'
import Aged21YearsFlagship from '../../../images/labels/aged-21-years-flagship.svg'
import FireCane from '../../../images/labels/fire-cane-v2.svg'
import IPA from '../../../images/labels/ipa-v2.svg'
import ProjectXX from '../../../images/labels/project-xx.svg'
import Aged23Years from '../../../images/labels/aged-23-years.svg'
import Aged18YearsFlagship from '../../../images/labels/aged-18-years-flagship.svg'
import Vat01 from '../../../images/labels/vat01.svg'
import Vat02 from '../../../images/labels/vat02.svg'
import Vat03 from '../../../images/labels/vat03.svg'
import Vat04 from '../../../images/labels/vat04.svg'

export default {
  'aged-12-years-flagship': Aged12YearsFlagship,
  'aged-12-years': Aged12Years,
  'aged-14-years-flagship': Aged14YearsFlagship,
  'aged-15-years-flagship': Aged15YearsFlagship,
  'aged-15-years': Aged15Years,
  'aged-15-years-gtr': Aged15YearsGTR,
  'aged-18-years': Aged18Years,
  'aged-18-years-flagship': Aged18YearsFlagship,
  'aged-21-years': Aged21YearsSVG,
  'aged-21-years-flagship': Aged21YearsFlagship,
  'aged-23-years': Aged23Years,
  'fire-cane': FireCane,
  'ipa': IPA,
  'project-xx': ProjectXX,
  'vat01': Vat01,
  'vat02': Vat02,
  'vat03': Vat03,
  'vat04': Vat04,
}
