import React, { FormEvent, useMemo, useState } from 'react'
import { useEditorDispatch, useEditorState } from '../../../state/editor-context'
import { EditorDispatchType } from '../../../types/enums'
import { Button, Control, InputField } from '../../elements'
import BatchField from './batch-field'
import MainField from './main-field'
import SubField from './sub-field'

export default function CreateLabelForm() {
  const { subField, batchField, mainField, isCensoredWordsAllowed } = useEditorState()
  const dispatch = useEditorDispatch()
  const [mainFieldError, setMainFieldError] = useState(false)
  const [subFieldError, setSubFieldError] = useState(false)
  const [batchFieldError, setBatchFieldError] = useState(false)

  const canProcceed = useMemo(() => {
    return (
      mainFieldError === false &&
      subFieldError === false &&
      batchFieldError === false &&
      mainField.trim() !== '' &&
      batchField?.trim() !== ''
    )
  }, [batchField, batchFieldError, mainField, mainFieldError, subFieldError])

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    dispatch({
      type: EditorDispatchType.nextStep,
    })
  }

  const onMainFieldReport = (error: boolean) => setMainFieldError(error)
  const onSubFieldReport = (error: boolean) => setSubFieldError(error)
  const onBatchFieldReport = (error: boolean) => setBatchFieldError(error)

  return (
    <form onSubmit={onSubmit} className="u__mc--vertical c__create-label-form">
      {typeof batchField !== 'undefined' && <BatchField reportError={onBatchFieldReport} />}
      <MainField reportError={onMainFieldReport} />
      {typeof subField !== 'undefined' && <SubField reportError={onSubFieldReport} />}

      <InputField className="c__input-field--checkbox">
        <Control
          label={{
            htmlFor: 'languageFilter',
            children: 'Override language filter',
          }}
          input={{
            type: 'checkbox',
            name: 'languageFilter',
            id: 'languageFilter',
            checked: isCensoredWordsAllowed,
            onChange: () =>
              dispatch({
                type: EditorDispatchType.updateField,
                payload: {
                  key: 'isCensoredWordsAllowed',
                  value: !isCensoredWordsAllowed,
                },
              }),
          }}
        />
      </InputField>
      <p className="c__label-btn">
        <Button type="submit" disabled={!canProcceed}>
          Next
        </Button>
      </p>
    </form>
  )
}
