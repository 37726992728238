import { navigate } from 'gatsby'
import { Keys } from '../types/generic'

/**
 * Expecting to get a pathname similar to `/en/blah/blah`. Since the language
 * key is always first we can split, remove any empty strings (due to the starting '/')
 * and return the first item
 *
 * @param pathname
 * @returns
 */
export const getLanguageFromPathname = (pathname: string): Keys.Language => {
  return pathname.split('/').filter((item) => item)[0] as Keys.Language
}

export const navigateToGetStarted = (language: Keys.Language) => {
  navigate(`/${language}/`)
}

export const replaceLanguageInPathname = (pathname: string, replacement: string): string => {
  const currentLanguage = getLanguageFromPathname(pathname)
  return pathname.replace(currentLanguage, replacement)
}
