import React, { ChangeEvent, useEffect, useRef } from 'react'
import useRequiredBecameEmpty from '../../../hooks/use-required-became-empty'
import { useEditorDispatch, useEditorState } from '../../../state/editor-context'
import { Component } from '../../../types/components'
import { EditorDispatchType } from '../../../types/enums'
import { Control, InputField, MaxLengthIndicator } from '../../elements'
import InputErrorMessage from '../../elements/input-error-message'
import labelConfig from '../../labels/config'

export default function BatchField({ reportError }: Component.LabelFormField) {
  const { batchField, id } = useEditorState()
  const dispatch = useEditorDispatch()
  const batchFieldMax = labelConfig[id]?.batchField?.max || 4
  const becameEmpty = useRequiredBecameEmpty(batchField || '')
  const inputElement = useRef<HTMLDivElement>()

  useEffect(() => {
    reportError(becameEmpty)
  }, [becameEmpty, reportError])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const valueAsNumber = Number.parseInt(value, 10)
    const noneDigitMatches = value.match(/\D/g)

    if (noneDigitMatches !== null) {
      event.preventDefault()
      return
    }

    if (Number.isNaN(valueAsNumber) && value !== '') {
      event.preventDefault()
      return
    }

    if (valueAsNumber > (batchFieldMax === 4 ? 9999 : 999) || `${value}`.length > batchFieldMax) {
      event.preventDefault()
      return
    }

    dispatch({
      type: EditorDispatchType.updateField,
      payload: {
        key: 'batchField',
        value,
      },
    })
  }

  const onInputFocus = () => {
    dispatch({
      type: EditorDispatchType.updateFormFocus,
      payload: {
        focused: true,
        focusedElement: inputElement,
      },
    })
  }

  const onInputBlur = () => {
    dispatch({
      type: EditorDispatchType.updateFormFocus,
      payload: {
        focused: false,
      },
    })
  }

  return (
    <InputField hasMaxLength error={becameEmpty} ref={inputElement} id="batchField">
      <Control
        label={{
          htmlFor: 'batchField-field',
          children: 'Batch number',
        }}
        input={{
          value: batchField,
          onChange,
          name: 'batchField-field',
          id: 'batchField-field',
          max: batchFieldMax === 4 ? 9999 : 999,
          type: 'text',
          required: true,
          onFocus: onInputFocus,
          onBlur: onInputBlur,
        }}
      />
      <MaxLengthIndicator maxLength={batchFieldMax} currentValue={batchField} />
      {becameEmpty && (
        <InputErrorMessage>
          *This section is mandatory, please enter in the batch number on selected bottle.
        </InputErrorMessage>
      )}
    </InputField>
  )
}
