import React from 'react'
import { PageTitle } from '../../grouping'

export default function StepTwoHeader() {
  return (
    <PageTitle
      title={
        <>
          Personalise
          <br />
          your label
        </>
      }
      subTitle="Step two"
    />
  )
}
