import React, { ChangeEvent, FormEvent, useMemo, useState } from 'react'
import { useEditorDispatch } from '../../../state/editor-context'
import { EditorDispatchType } from '../../../types/enums'
import { Button, Control, InputField } from '../../elements'
import InputErrorMessage from '../../elements/input-error-message'

export default function ConfirmLabelForm() {
  const dispatch = useEditorDispatch()
  const [formState, setFormState] = useState({
    customerConfirm: false,
    glenfiddichConfirm: false,
  })
  const [errors, setErrors] = useState({
    customerConfirm: false,
    glenfiddichConfirm: false,
  })

  // const disableButton = useMemo(() => !formState.customerConfirm || !formState.glenfiddichConfirm, [
  //   formState.customerConfirm,
  //   formState.glenfiddichConfirm,
  // ])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    setFormState({
      ...formState,
      [name]: checked,
    })
  }

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (Object.values(formState).includes(false)) {
      setErrors({
        customerConfirm: !formState.customerConfirm,
        glenfiddichConfirm: !formState.glenfiddichConfirm,
      })

      return
    }

    dispatch({
      type: EditorDispatchType.nextStep,
    })
  }

  return (
    <form onSubmit={onSubmit} className="u__mc--vertical c__confirm-label-form">
      <InputField className="c__input-field--checkbox">
        <Control
          label={{
            htmlFor: 'customerConfirm',
            children: 'Customer confirms that the message is to their satisfaction.',
          }}
          input={{
            type: 'checkbox',
            name: 'customerConfirm',
            id: 'customerConfirm',
            checked: formState.customerConfirm,
            onChange,
          }}
        />
        {errors.customerConfirm && !formState.customerConfirm && (
          <InputErrorMessage>Please tick this checkbox to continue.</InputErrorMessage>
        )}
      </InputField>
      <InputField className="c__input-field--checkbox">
        <Control
          label={{
            htmlFor: 'glenfiddichConfirm',
            children: 'Glenfiddich representative agrees that the label is ready to print.',
          }}
          input={{
            type: 'checkbox',
            name: 'glenfiddichConfirm',
            id: 'glenfiddichConfirm',
            checked: formState.glenfiddichConfirm,
            onChange,
          }}
        />
        {errors.glenfiddichConfirm && !formState.glenfiddichConfirm && (
          <InputErrorMessage>Please tick this checkbox to continue.</InputErrorMessage>
        )}
      </InputField>
      <p className="c__label-btn">
        <Button type="submit" variant="secondary">
          Confirm
        </Button>
      </p>
    </form>
  )
}
